import { ESectionStatus } from '@/enums/ESectionStatus';
import { isTruthy } from '@/util/isTruthy';

/**
 * takes an array and returns an ESectionStatus based
 * on the number of non-falsey entries found in the array
 *
 * arrays -> checks if the length is 0
 * false -> checks if the value is strictly equal to false
 *
 * @export
 * @param {unknown[]} fields
 * @returns  {ESectionStatus}
 */
export function getSectionStatus<T>(fields: T[]): ESectionStatus {
  if (fields.every(isTruthy)) return ESectionStatus.COMPLETE;
  if (fields.some(isTruthy)) return ESectionStatus.PARTIAL;
  else return ESectionStatus.INCOMPLETE;
}
