/* eslint-disable @typescript-eslint/no-explicit-any */
import { isArray } from '@vue/shared';

/**
 * takes an array and returns an ESectionStatus based
 * on the number of non-falsey entries found in the array
 *
 * arrays -> checks if the length is 0
 * false -> checks if the value is strictly equal to false
 *
 * @export
 * @param {unknown[]} fields
 * @returns  {ESectionStatus}
 */
export function isTruthy(field: any) {
  if (isArray(field)) return field.length !== 0;
  else if (field === false) return true;
  else return !!field;
}
