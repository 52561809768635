var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", { staticClass: "w-full px-12 mt-8" }, [
    _c("h1", { staticClass: "mb-8 text-3xl" }, [_vm._v(_vm._s(_vm.title))]),
    _c("div", [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }